<template>
  <div class="ProtocolMgrForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      :footerShow="type !== 'check'"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item label="协议名称" v-if="type === 'check'">
              <span v-text="form.agreementName" />
          </el-form-item>
          <el-form-item label="协议名称" :rules="[{ required: true, message: '请输入协议名称', trigger: 'change'}]" prop="agreementName"
            v-else
          >
            <v-input :width="width" v-model="form.agreementName" placeholder="请输入协议名称" />
          </el-form-item>
          <div v-if="!form.id">
            <el-form-item label="登录端类型" :rules="[{ required:true, message: '请选择登录端类型', trigger: 'change' }]" prop="appType">
              <v-select :width="width" v-model="form.appType" :options="appTypeOps"/>
            </el-form-item>
            <el-form-item label="协议类型" :rules="[{ required:true, message: '请选择协议类型', trigger: 'change' }]" prop="agreementType">
            <v-select2 ref="agreementTypeRef" :width="width" v-model="form.agreementType" placeholder="查询类型" v-bind="agreementTypeParams" :subjoin="agreementTypeSubjoin"  @onChange="onChange"/>
            </el-form-item>
            <!-- 协议类型带隐私 并且是app -->
            <el-form-item label="协议版本" :rules="[{ required:true, message: '请选择协议版本', trigger: 'change' }]" prop="versionCode"
              v-if="form.agreementType && agreementTypeValue.indexOf('隐私') != -1 && form.belongType === 2"
            >
              <v-datepicker v-model="form.versionCode" type="date" label="" />
            </el-form-item>
            <el-form-item label="协议层级" :rules="[{ required:true, message: '请选择协议层级', trigger: 'change' }]" prop="belongType">
              <v-select :width="width" v-model="form.belongType" :options="belongTypeOps" @change="belongTypeChange"/>
            </el-form-item>
            <el-form-item v-if="belongOrgVisible" label="协议归属" :rules="[{ required:true, message: '请选择协议归属', trigger: 'change' }]" prop="belongOrg">
              <v-select2 :width="width" v-model="form.belongOrg" placeholder="查询归属" v-bind="regionParams" />
            </el-form-item>
            <el-form-item v-if="form.belongType === 2" label="协议归属" :rules="[{ required:true, message: '请选择协议归属', trigger: 'change' }]" prop="belongOrg">
              <v-select2 :width="width" v-model="form.belongOrg" placeholder="查询归属" v-bind="appParams" :subjoin="{ appType: form.appType }" />
            </el-form-item>
            <el-form-item v-if="form.belongType === 3" label="协议归属" :rules="[{ required:true, message: '请选择协议归属', trigger: 'change' }]" prop="belongOrg">
              <v-select :width="width" v-model="form.belongOrg" :options="protocolMPOps" />
              <!-- <v-select2 :width="width" v-model="form.belongOrg" placeholder="查询归属" v-bind="protocolMPParams" /> -->
            </el-form-item>
          </div>

          <div v-else>

            <el-form-item label="登录端类型">
              <span v-text="appTypeText" />
            </el-form-item>
            <el-form-item label="协议类型">
              <span v-text="agreementTypeText" />
            </el-form-item>
            <el-form-item label="协议版本" v-if="$route.query.type === 'check' && form.belongType === 2 && agreementTypeText.indexOf('隐私') != -1">
              <v-select v-model="form.versionCode" :options="versionOps" label=""
                @change="versionSelectChange"
              ></v-select>
            </el-form-item>
            <el-form-item label="协议版本" :rules="[{ required:true, message: '请选择协议版本', trigger: 'change' }]" prop="versionCode"
              v-else-if="agreementTypeText && agreementTypeText.indexOf('隐私') != -1 && form.belongType === 2"
            >
              <v-datepicker v-model="form.versionCode" type="date" label="" />
            </el-form-item>
            <el-form-item label="协议层级">
              <span v-text="belongTypeText" />
            </el-form-item>
            <el-form-item v-if="belongOrgTextVisible" label="协议归属">
              <span v-text="belongOrgText" />
            </el-form-item>
          </div>

          <el-form-item label="协议内容" :rules="[{ required: true, message: '请输入协议内容', trigger: 'change' }]" prop="agreementDoc">
            <v-ueditor v-model="form.agreementDoc" @ready="editReady" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { regionParams } from 'common/select2Params'
import { createURL, updateURL, getDetailUrl, agreementTypeUrl, searchAPPURL, getMPBelongURL,
  getVersionOps, getAppAgreementVersionInfo
} from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { vUeditor } from 'components/control'
import { appTypeMap, belongTypeMap, setAppTypeOps, setBelongTypeOps } from './map'

const platformBelongTypeOps = setBelongTypeOps()
const regionBelongTypeOps = setBelongTypeOps().splice(1, 1)

export default {
  name: 'ProtocolMgrForm',
  components: {
    Col2Block,
    Col2Detail,
    vUeditor
  },
  data () {
    return {
      submitConfig: {
        queryUrl: '',
        submitUrl: ''
      },
      appTypeOps: setAppTypeOps(),
      belongTypeOps: [],
      agreementTypeParams: {
        searchUrl: agreementTypeUrl,
        request: {
          text: 'value',
          value: 'code'
        },
        response: {
          text: 'value',
          value: 'code'
        }
      },
      regionParams,
      appTypeText: undefined,
      agreementTypeText: undefined,
      belongTypeText: undefined,
      belongOrgText: undefined,
      belongOrgTextVisible: false,
      form: {
        id: undefined,
        agreementName: '',
        appType: undefined,
        agreementType: undefined,
        belongType: undefined,
        belongOrg: undefined,
        agreementDoc: '',
        versionCode: '',
      },
      appParams: {
        searchUrl: searchAPPURL,
        request: {
          text: 'appName',
          value: 'id'
        },
        response: {
          text: 'appName'
        }
      },
      width: 200,
      protocolMPOps: [],
      agreementTypeValue: '',
      versionOps: [],
      agreementTypeCode: undefined,
      type: '',// edit 编辑  check 查看
      versionObj: {},// 协议版本的选中那一项
      firstEnter: true
    }
  },
  mounted () {
    const { id } = this.$route.query
    this.type = this.$route.query.type
    if (id !== undefined) {
      this.form.id = id
      // 查看进来的
      
      if(this.type === 'check') {
        this.$setBreadcrumb('查看历史协议')
      }else if(this.type === 'edit') {
        this.$setBreadcrumb('编辑协议')
      }
      
      this.submitConfig.submitUrl = updateURL
      this.submitConfig.queryUrl = `${getDetailUrl}/${id}`
      this.$refs.formPanel.getData()
    } else {
      this.$setBreadcrumb('新增协议')
      this.submitConfig.submitUrl = createURL
      if (this.userType === '102') {
        this.belongTypeOps = regionBelongTypeOps
      } else {
        this.belongTypeOps = platformBelongTypeOps
      }
      this.form.belongType = this.belongTypeOps[0].value
      this.form.appType = this.appTypeOps[0].value
      this.getMicroAppInfoData()
    }
  },
  computed: {
    userType () {
      return this.$store.state.userInfo.userType
    },
    belongOrgVisible () {
      return this.form.belongType === 1
    },
    agreementTypeSubjoin () {
      return {
        typeValue: 'APPAGREEMENT',
        codeLevel: 2,
        superCode: this.form.appType
      }
    }
  },
  watch: {
    ['form.versionCode']: {
      handler:function (value) {
        // 如果有说明存在这个版本的历史记录
        const versionObj = this.versionOps.find(item => item.versionCode == value)
        this.versionObj = versionObj
        if(versionObj) {
          // 原来的查看
          this.form.versionFlag = 0
          this.form.agreementVersionId = versionObj.appAgreementId
          this.$axios.get(`${getAppAgreementVersionInfo}`,{
            params: {
              agreementId: versionObj.appAgreementId,
              agreementType: versionObj.agreementType,
              appCode: versionObj.belongOrg,
            }
          }).then(res => {
            this.form.agreementDoc = res.data[0].agreementDoc
          })
        }else {
          // 原来的编辑
          this.form.versionFlag = 1
          if(!this.firstEnter) {
            this.form.agreementDoc = ''
          }else {
            this.firstEnter = false
          }
        }
      }
    }
  },
  methods: {
    update (data) {
      this.appTypeText = appTypeMap[data.appType]
      this.agreementTypeText = data.agreementType
      this.belongTypeText = belongTypeMap[data.belongType]
      this.belongOrgText = data.belongOrgName
      this.agreementTypeCode = data.agreementTypeCode
      if (data.belongType && data.belongType !== 9) {
        // 租户 APP 小程序都要显示协议归属
        this.belongOrgTextVisible = true
      }
      this.form.belongOrg = data.belongOrg
      this.form.agreementName = data.agreementName
      this.form.agreementDoc = data.agreementDoc
      this.form.versionCode = data.versionCode.replace(/\./g, '-')
      this.form.belongType = data.belongType
      this.form.agreementType = data.agreementTypeCode
      this.form.appType = data.appType
      this.getVersionOps()
    },
    submitBefore (data) {
      if (!data['id']) {
        if (this.form.belongType === 9) {
          data['belongOrg'] = 'ad'
        }
      }

      // 操作日志的数据对象参数
      let dataObject
      if (this.form.id) {
        dataObject = `${data.agreementName}-${this.agreementTypeText}-${this.belongTypeText}`
      } else {
        if (this.$refs.agreementTypeRef && this.$refs.agreementTypeRef.getBackTextValue()) {
          dataObject = `${data.agreementName}-${this.$refs.agreementTypeRef.getBackTextValue()}-${belongTypeMap[data.belongType]}`
        }
      }
      data.dataObject = dataObject
      return true
    },

    belongTypeChange (val) {
      this.form.belongOrg = undefined
    },

    // 获取小程序数据
    async getMicroAppInfoData () {
      // getMPBelongURL
      const { data, status } = await this.$axios.get(getMPBelongURL)
      if (status === 100) {
        let list = data || []
        list.forEach(item => {
          item.text = item.name
          item.value = item.appId
        })
        this.protocolMPOps = list
      }
    },
    // 拿到协议类型的名字 判断协议版本的显示
    onChange(data) {
      this.agreementTypeValue = data.value
    },
    // 获取协议版本选项
    getVersionOps() {
      this.$axios.get(getVersionOps, {
        params: {
          agreementType: this.agreementTypeCode,
          appCode: this.form.belongOrg
        }
      }).then(res => {
        // if(res.data.length > 0) {
        //   this.versionObj = res.data[0]
        // }
        this.versionOps = res.data.map(item => {
          const versionCode = item.versionCode.replace(/\./g,'-')
          item.versionCode = versionCode
          item.value = versionCode
          // item.text = versionCode
          return item
        })
      })
    },
    // 协议版本切换
    versionSelectChange(value) {
      const obj = this.versionOps.find(item => {
        return value === item.versionCode
      })
      if(!obj) return;
      this.versionObj = obj
      this.$axios.get(`${getAppAgreementVersionInfo}`,{
        params: {
          agreementId: obj.appAgreementId,
          agreementType: obj.agreementType,
          appCode: obj.belongOrg,
        }
      }).then(res => {
        this.form.agreementDoc = res.data[0].agreementDoc
      })
    },
    editReady(instance) {
      if(this.type === 'check') {
        instance.setDisabled()
      }
    }
  }
}
</script>

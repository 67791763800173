var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ProtocolMgrForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              footerShow: _vm.type !== "check",
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _vm.type === "check"
                    ? _c("el-form-item", { attrs: { label: "协议名称" } }, [
                        _c("span", {
                          domProps: {
                            textContent: _vm._s(_vm.form.agreementName),
                          },
                        }),
                      ])
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "协议名称",
                            rules: [
                              {
                                required: true,
                                message: "请输入协议名称",
                                trigger: "change",
                              },
                            ],
                            prop: "agreementName",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              width: _vm.width,
                              placeholder: "请输入协议名称",
                            },
                            model: {
                              value: _vm.form.agreementName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "agreementName", $$v)
                              },
                              expression: "form.agreementName",
                            },
                          }),
                        ],
                        1
                      ),
                  !_vm.form.id
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "登录端类型",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择登录端类型",
                                    trigger: "change",
                                  },
                                ],
                                prop: "appType",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  width: _vm.width,
                                  options: _vm.appTypeOps,
                                },
                                model: {
                                  value: _vm.form.appType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "appType", $$v)
                                  },
                                  expression: "form.appType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "协议类型",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择协议类型",
                                    trigger: "change",
                                  },
                                ],
                                prop: "agreementType",
                              },
                            },
                            [
                              _c(
                                "v-select2",
                                _vm._b(
                                  {
                                    ref: "agreementTypeRef",
                                    attrs: {
                                      width: _vm.width,
                                      placeholder: "查询类型",
                                      subjoin: _vm.agreementTypeSubjoin,
                                    },
                                    on: { onChange: _vm.onChange },
                                    model: {
                                      value: _vm.form.agreementType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "agreementType", $$v)
                                      },
                                      expression: "form.agreementType",
                                    },
                                  },
                                  "v-select2",
                                  _vm.agreementTypeParams,
                                  false
                                )
                              ),
                            ],
                            1
                          ),
                          _vm.form.agreementType &&
                          _vm.agreementTypeValue.indexOf("隐私") != -1 &&
                          _vm.form.belongType === 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "协议版本",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择协议版本",
                                        trigger: "change",
                                      },
                                    ],
                                    prop: "versionCode",
                                  },
                                },
                                [
                                  _c("v-datepicker", {
                                    attrs: { type: "date", label: "" },
                                    model: {
                                      value: _vm.form.versionCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "versionCode", $$v)
                                      },
                                      expression: "form.versionCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "协议层级",
                                rules: [
                                  {
                                    required: true,
                                    message: "请选择协议层级",
                                    trigger: "change",
                                  },
                                ],
                                prop: "belongType",
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  width: _vm.width,
                                  options: _vm.belongTypeOps,
                                },
                                on: { change: _vm.belongTypeChange },
                                model: {
                                  value: _vm.form.belongType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "belongType", $$v)
                                  },
                                  expression: "form.belongType",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.belongOrgVisible
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "协议归属",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择协议归属",
                                        trigger: "change",
                                      },
                                    ],
                                    prop: "belongOrg",
                                  },
                                },
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        attrs: {
                                          width: _vm.width,
                                          placeholder: "查询归属",
                                        },
                                        model: {
                                          value: _vm.form.belongOrg,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "belongOrg", $$v)
                                          },
                                          expression: "form.belongOrg",
                                        },
                                      },
                                      "v-select2",
                                      _vm.regionParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.belongType === 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "协议归属",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择协议归属",
                                        trigger: "change",
                                      },
                                    ],
                                    prop: "belongOrg",
                                  },
                                },
                                [
                                  _c(
                                    "v-select2",
                                    _vm._b(
                                      {
                                        attrs: {
                                          width: _vm.width,
                                          placeholder: "查询归属",
                                          subjoin: {
                                            appType: _vm.form.appType,
                                          },
                                        },
                                        model: {
                                          value: _vm.form.belongOrg,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "belongOrg", $$v)
                                          },
                                          expression: "form.belongOrg",
                                        },
                                      },
                                      "v-select2",
                                      _vm.appParams,
                                      false
                                    )
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.form.belongType === 3
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "协议归属",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择协议归属",
                                        trigger: "change",
                                      },
                                    ],
                                    prop: "belongOrg",
                                  },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      width: _vm.width,
                                      options: _vm.protocolMPOps,
                                    },
                                    model: {
                                      value: _vm.form.belongOrg,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "belongOrg", $$v)
                                      },
                                      expression: "form.belongOrg",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "登录端类型" } },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(_vm.appTypeText),
                                },
                              }),
                            ]
                          ),
                          _c("el-form-item", { attrs: { label: "协议类型" } }, [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.agreementTypeText),
                              },
                            }),
                          ]),
                          _vm.$route.query.type === "check" &&
                          _vm.form.belongType === 2 &&
                          _vm.agreementTypeText.indexOf("隐私") != -1
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "协议版本" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm.versionOps,
                                      label: "",
                                    },
                                    on: { change: _vm.versionSelectChange },
                                    model: {
                                      value: _vm.form.versionCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "versionCode", $$v)
                                      },
                                      expression: "form.versionCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm.agreementTypeText &&
                              _vm.agreementTypeText.indexOf("隐私") != -1 &&
                              _vm.form.belongType === 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "协议版本",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择协议版本",
                                        trigger: "change",
                                      },
                                    ],
                                    prop: "versionCode",
                                  },
                                },
                                [
                                  _c("v-datepicker", {
                                    attrs: { type: "date", label: "" },
                                    model: {
                                      value: _vm.form.versionCode,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "versionCode", $$v)
                                      },
                                      expression: "form.versionCode",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("el-form-item", { attrs: { label: "协议层级" } }, [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.belongTypeText),
                              },
                            }),
                          ]),
                          _vm.belongOrgTextVisible
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "协议归属" } },
                                [
                                  _c("span", {
                                    domProps: {
                                      textContent: _vm._s(_vm.belongOrgText),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "协议内容",
                        rules: [
                          {
                            required: true,
                            message: "请输入协议内容",
                            trigger: "change",
                          },
                        ],
                        prop: "agreementDoc",
                      },
                    },
                    [
                      _c("v-ueditor", {
                        on: { ready: _vm.editReady },
                        model: {
                          value: _vm.form.agreementDoc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "agreementDoc", $$v)
                          },
                          expression: "form.agreementDoc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }